import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';
import IOHeadline from 'components/io-headline';
import { AnimatedContainer } from 'components/header';
import { Intro, IntroText } from 'components/intro/intro.css';

const How = ({ data }) => (
  <Layout>
    <AnimatedContainer>
      <IOHeadline as="h1">
        <Intro>
          <IntroText>
            {data.howJson.content.childMarkdownRemark.rawMarkdownBody}
          </IntroText>
        </Intro>
      </IOHeadline>
    </AnimatedContainer>
  </Layout>
);

How.propTypes = {
  data: PropTypes.object.isRequired,
};

export default How;

export const query = graphql`
  query HowQuery {
    howJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
